import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { T } from "../../hooks/useTranslate";
import { useLanguageContext } from "../../hooks/LanguageContext";
import usePopup from "../../hooks/usePopup";
import { useMixPanel } from "../../hooks/useMixPanel";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface AnnouncementProps {
  data: {
    contentId: string;
    isActive: boolean;
    links: {
      path?: string;
      main?: string;
    };
    resourceLinks: {
      main: string;
    };
    styles: {
      maxWidth: string;
    };
    targetCountries: string[];
    text: {
      buttonTextKey: string;
      subtextKey: string;
      titleTextKey: string;
      subtextKey2?: string;
    };
    type: 'announcement';
  };
  onClose?: () => void;
}

const Announcement = ({ data }: AnnouncementProps) => {
  const { styles } = data
  const { currentLanguage } = useLanguageContext();
  const { handleClosePopup } = usePopup()
  const { logEvent } = useMixPanel()
  const navigate = useNavigate()
  // @ts-ignore
  const { userInfo } = useAuth()
  const getImage = () => {
    switch (currentLanguage) {
      case "fr":
        return "assets/jpg/announcement-fr.jpg";
      case "es":
        return "assets/jpg/announcement-es.jpg";
      default:
        return data.resourceLinks.main
    }
  }

  const handleRedirect = () => {
    logEvent("announcement_clicked", {
      link: data?.links?.path || data?.links?.main,
      unicity_id: userInfo?.unicityId,
      country_code: userInfo?.countryCode,
      popup_content_id: data?.contentId,
    })
    if (data?.links?.path) {
      navigate(data.links.path)
      handleClosePopup()
      return
    }
    window.open(data.links.main, "_blank");
    handleClosePopup()
  }

  return (
    <Box sx={{ maxWidth: styles.maxWidth, overflow: "hidden", position: "relative" }}>
      <IconButton
        onClick={() => handleClosePopup()}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 10,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }}
        data-test-id="announcement-close-button"
        aria-label="close announcement"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: "100%" }}>
        <img
          src={getImage()}
          alt="announcement"
          style={{
            width: "100%",
            maxHeight: "430px",
            objectFit: "cover"
          }}
        />
      </Box>
      <Stack
        sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        px={3}
        pb={5}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "120%",
            mt: 2
          }}
        >
          <T>{data.text.titleTextKey}</T>
        </Typography>
        <Stack>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "150%",
              mt: 2,
              wordBreak: "break-word",
              overflowWrap: "break-word",
              maxWidth: "100%"
            }}
          >
            <T>{data.text.subtextKey}</T>
          </Typography>
          {data.text.subtextKey2 && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "150%",
                mt: 2,
                wordBreak: "break-word",
                overflowWrap: "break-word",
                maxWidth: "100%"
              }}
            >
              <T>{data.text.subtextKey2}</T>
            </Typography>
          )}
        </Stack>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          textDecoration: 'none'
        }}>
          <Button
            data-test-id="announcement-button"
            onClick={handleRedirect}
            sx={{
              borderRadius: "33px",
              backgroundColor: "#003764",
              '&:hover': {
                backgroundColor: "#003764",
              },
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "100%",
              width: "55%",
              p: 1.5
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "120%",
                textAlign: "center"
              }}
            >
              <T>{data.text.buttonTextKey}</T>
            </Typography>
          </Button>
        </Box>

        {/* </Link> */}
      </Stack>
    </Box>
  )
}

export default Announcement