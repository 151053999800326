import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { keyframes } from '@mui/material';
import { Box } from '@mui/material';

const pulsing = (color) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color}80;
  }
  70% {
    box-shadow: 0 0 0 8px ${color}00;
  }
  100% {
    box-shadow: 0 0 0 0 ${color}00;
  }
`;

const getColor = (variant) => {
  switch (variant) {
    case 'error':
      return '#f44336'; // red[500]
    case 'warning':
      return '#FFEB3B'; // yellow[200]
    case 'info':
      return '#03A9F4'; // lightBlue[500]
    case 'success':
      return '#4CAF50'; // green[500]
    default:
      return '#f44336'; // red[500]
  }
};

const getCircleStyle = (color, pulse) => ({
  backgroundColor: color,
  boxShadow: `0 0 0 ${color}80`,
  animation: pulse ? `${pulsing(color)} 1500ms ease-out infinite` : 'none',
});

const Circle = styled('span')(({ color, pulse, padRight }) => ({
  position: 'relative',
  right: 0,
  top: "-5px",
  width: 8,
  height: 8,
  marginLeft: padRight,
  borderRadius: '50%',
  ...getCircleStyle(color, pulse),
}));

const ActionableIndicator = ({children, invisible, padRight=4, withBorder=false, variant='error'}) => {
  const [pulse, setPulse] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPulse(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const color = getColor(variant);

  return (
    <Box sx={{position:'relative', display: 'inline-flex', alignItems: 'center'}}>
      {children}
      {!invisible && (
        <Circle 
          className='indicator-dot' 
          padRight={padRight} 
          color={color} 
          pulse={pulse} 
        />
      )}
    </Box>
  )
}

export { ActionableIndicator }